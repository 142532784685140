<template>
    <div class="injury_grade">
        <div
            v-for="(item,index) in gradeList"
            :key="item.id"
            class="injury_grade_item"
            :style="`width: ${index==grade-1?'18px': '20px'};
                height:${index==grade-1?'20px': '6px'};
                border-radius:${index==grade-1?'3px': ''};
                background: linear-gradient(to right, ${item[0]}, ${item[1]});`"
        >
            <span
                class="item_title"
                v-if="index==grade-1"
            >{{grade}}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        grade: {
            type: [String, Number]
        },
        gradeList: {
            type: Array,
            default: () => {
                return [
                    ['#73FF9A', '#9BF092'],
                    ['#B9E58B', '#F1D080'],
                    ['#FFCB7C', '#FF985D'],
                    ['#FF8653', '#FF4329'],
                    ['#D90101', '#D90101'],
                ]
            }
        }
    },
    data () {
        return {
        }
    }
}
</script>

<style lang="scss" scoped>
.injury_grade {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.injury_grade_item {
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    transform: skew(-10deg)
}
.item_title {
    font-family: akrobatBold;
    font-size: 26px;
    text-align: center;
    color: #fff;
    border-radius: 3px;
    flex-shrink: 0;
    transform: skew(10deg)
}
</style>
